.lazyWrapper {
  overflow: hidden;
}

.lazySource {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.lazyLoaded {
  opacity: 1;
}

.lqip {
  display: block;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  opacity: 1;
}

.lqipLoaded {
  -webkit-animation: fadeout 0.3s linear forwards;
  animation: fadeout 0.3s linear forwards;
  animation-delay: 0.15s;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
